.prod-admin-subtitle {
  font-size: 12px;
  margin: 0px;
  opacity: 50%;

}

.product-btn {
  color: #ffffff;
  // background-color: rgb(38, 154, 216);
  background-image: linear-gradient(rgb(38, 154, 216), #025D8D);
  //opacity: 90%;
  border-radius: 15px;
  border: none;
}

.product-section .col-md-6 {
  margin: 10px;
}

// /* Use a media query to add a break point at 800px: */
@media screen and (max-width:800px) {
  // .dash-section {
  //   padding: 10px 5px;
  // }
}
